
import { defineComponent, ref, onMounted, computed } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import NotificationService from "@/core/services/car/NotificationService";

// import InvoiceAction from "@/components/dropdown/car/InvoiceAction.vue";


interface NotificationBodyParam {
  per_page: string;
}

interface ApproveInvoiceParam {
  invoice_id: number;
  status: any;
}

export default defineComponent({
  name: "notification-list",
  components: {
    // InvoiceAction,
    // Overlay,
  },
  data() {
    return {
      // opened: false,
      // visible: false,
     
    };
  },
  setup() {
    // let customers_obj = ref([]);

    let responseResult = ref<any>({});
    responseResult.value.success = false;
    responseResult.value.failed = false;
    responseResult.value.execute = false;
    let approveInvoiceParam = {} as ApproveInvoiceParam;
    const store = useStore();
    let bodyParameters = {} as NotificationBodyParam;
    let exportUrl = ref<string>("");
    bodyParameters = {
      per_page: "50"
    };

    onMounted(() => {
      MenuComponent.reinitialization();

      setCurrentPageBreadcrumbs("Notifications",[]);
      // API Action to get customers list
      fetchNotificationList(bodyParameters);
    });

    const getPaginationUrl = (index) => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      let pagenationUrl = index.split("=");
      NotificationService.getAllNotificationList(bodyParameters, pagenationUrl[1]);
    };
    const onPerPageChange = (event) => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      bodyParameters.per_page = event.target.value;
      fetchNotificationList(bodyParameters);
    };

    let loadingNotif = ref(false);
    const fetchNotificationList = (bodyParameters) => {      
      loadingNotif.value = false;
      NotificationService.getAllNotificationList({ per_page: 50 }, 1).then((data) => {
        loadingNotif.value = true;
     })
    };
    

    const search = ref<string>("");
  
    
 
    let disableSaveButton = computed(() => {
      return store.getters.getDisableButtonStatus;
    });
    // let loadApprovalInformation = computed(() => {
    //   return store.getters.getApprovalListingDom;
    // });
  
    return {
      search,
      onPerPageChange,
      getPaginationUrl,
      bodyParameters,
      responseResult,
      disableSaveButton,
      exportUrl,
      loadingNotif,
    };
  },
});
